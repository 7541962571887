.contacts {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}

.contacts--img {
  position: absolute;
  right: 5%;
  top: 25%;
  width: 280px;
  pointer-events: none;
}

.contacts--container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6rem 6rem 3rem;
  width: 80%;
  height: 100%;
  margin-top: 2rem;
}

.contacts--container>h1 {
  font-family: var(--primaryFont);
  font-size: 2.5rem;
  margin-bottom: 2.5rem;
  align-self: center;
}

.contacts-body {
  padding-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.contacts-form {
  display: flex;
  flex: 0.4;
  width: 100%;
}

.contacts-form form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
}

.input-container {
  width: 100%;
}

.form-input {
  height: 50px;
  box-sizing: border-box;
  border-radius: 50px;
  outline: none;
  margin-bottom: 2rem;
  padding: 0.7rem 0.7rem;
  border-radius: 20px;
  width: 100%;
  resize: none;
}

.form-message {
  height: 150px;
  box-sizing: border-box;
  border-radius: 50px;
  outline: none;
  margin-bottom: 2rem;
  padding: 0.7rem 0.7rem;
  border-radius: 20px;
  width: 100%;
  resize: none;
}

.submit-btn button {
  border: none;
  outline: none;
  width: 140px;
  height: 50px;
  border-radius: 50px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
}

.submit-btn button p {
  font-size: 16px;
  font-family: var(--primaryFont);
}

.submit-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.send-icon {
  font-size: 25px;
  transform-origin: center;
  transform: translate(5px, -3px) rotate(-30deg);
}

.success-icon {
  font-size: 28px;
  transition: all 0.3s 0.8s ease-in-out;
}

.contacts-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  flex: 0.6;
  box-sizing: border-box;
  padding-left: 7rem;
  margin-top: 1.2rem;
}

.personal-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 2.5rem;
}

.personal-details p {
  margin-left: 1.5rem;
  font-size: 22px;
  line-height: 110%;
  font-weight: 500;
  font-family: var(--primaryFont);
  width: 70%;
  word-break: break-word;
}

.socialmedia-icons {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  align-items: left;
  justify-content: left;
  gap: 1.5rem;
  margin-top: 4rem;
}

@media (min-width: 992px) and (max-width: 1380px) {
  .contacts--img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 240px;
  }

  .contacts--container {
    width: 90%;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 992px) {
  .contacts--img {
    display: none;
  }

  .contacts--container {
    width: 100%;
    margin-top: 1rem;
  }

  .contacts--container>h1 {
    font-size: 3rem;
    margin: 0 0 1.2rem 0;
  }

  .contacts-details {
    padding-left: 4rem;
  }

  .personal-details p {
    margin-left: 1rem;
    font-size: 1rem;
  }
}

@media screen and (max-width: 800px) {
  .contacts--container h1 {
    font-size: 3rem;
    margin-bottom: 30px;
  }

  .contacts--container {
    padding: 2rem;
    margin-top: 0rem;
    align-items: center;
  }

  .contacts-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contacts-form {
    display: flex;
    flex: 1;
    width: 80%;
  }

  .form-input {
    margin-bottom: 0.45rem;
  }

  .contacts-form form {
    align-items: flex-end;
  }

  .contacts-details {
    padding-left: 0rem;
    padding: 1rem;
    align-items: center;
    margin-top: 2rem;
  }

  .socialmedia-icons {
    width: 100%;
    gap: 1.5rem;
    margin-top: 2rem;
  }

  .personal-details {
    margin-bottom: 1.8rem;
  }

  .personal-details p {
    margin-left: 1.2rem;
    width: 95%;
  }
}

@media screen and (max-width: 600px) {
  .contacts-form {
    display: flex;
    flex: 1;
    width: 100%;
  }

  .contacts--container h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 400px) {
  .contacts-details {
    padding-left: 0rem;
    padding: 0rem;
    align-items: center;
    margin-top: 2rem;
  }

  .submit-btn {
    align-self: center;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
  .contacts-details {
    padding-left: 0rem;
    padding: 0rem;
  }
}

@keyframes spin {
  0% {
    opacity: 1;
    transform: translate(5px, -3px) rotate(0deg);
  }

  /* 60%{
      opacity: 0;
    }
    80%{
      opacity: 1;
    } */
  100% {
    transform: rotate(359deg);
  }
}

@keyframes fly {

  /* 0%,100%{
        transform: translate(5px,-3px) rotate(-30deg);
        opacity: 1;
    }
    25%{
        transform: translate(15px,-10px) rotate(-30deg);
        opacity: 0.8;
    }
    50%{
        transform: translate(20px,-12px) rotate(-30deg);
        opacity: 0;
    }
    60%{
        transform: translate(-12px,10px) rotate(-30deg);
        opacity: 0;
    }
    75%{
        opacity: 0.8;
    } */
  10% {
    transform: rotate(0deg);
  }

  20% {
    transform: translateX(-10px);
  }

  70% {
    transform: translateX(60px);
  }

  100% {
    transform: translateX(80px);
  }
}