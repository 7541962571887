.experience {
  padding-top: 4rem;
  min-height: fit-content;
}

.experience-body {
  display: grid;
  grid-template-columns: 35% 65%;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.experience-description {
  width: 100%;
  flex: 0.65;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-right: 2rem;
}

.experience-description > h1 {
  font-size: 2.5rem;
  font-family: var(--primaryFont);
  margin-bottom: 2rem;
}

.experience-card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90%;
  height: 140px;
  padding: 1.5rem;
  border-radius: 20px;
  margin-bottom: 1.5rem;
  transition: background-color 200ms ease-in-out;
}

.expcard-img {
  border-radius: 50%;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.expcard-img > img {
  width: 36px;
  pointer-events: none;
}

.experience-details {
  margin-left: 0.6rem;
}

.experience-details > h6 {
  font-family: var(--primaryFont);
  font-size: 0.85rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.experience-details > h4 {
  font-family: var(--primaryFont);
  font-size: 1.225rem;
  font-weight: 600;
}

.experience-details > h5 {
  font-family: var(--primaryFont);
  font-size: 1.15rem;
  font-weight: 600;
}

.experience-image {
  box-sizing: border-box;
  padding: 1rem;
  flex: 0.35;
  margin-left: 7%;
  margin-top: 1rem;
  pointer-events: none;
}

.experience-image > img {
  width: 100%;
  pointer-events: none;
}

@media screen and (max-width: 992px) {
  .experience-body {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .experience {
    min-height: 100%;
  }
  /* .experience-image {
    display: none;
  } */
  .experience-description {
    flex: 1;
    margin: auto;
  }
  .experience-description > h1 {
    font-size: 3.2rem;
  }
  .experience-card {
    width: 100%;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: 800px) {
  .experience-description > h1 {
    font-size: 3rem;
    margin-bottom: 2rem;
    align-self: center;
  }
  .experience-description {
    padding: 1rem;
  }
  .experience-card {
    padding: 1rem;
    margin-bottom: 1.5rem;
    height: 130px;
  }
  .expcard-img {
    border-radius: 50%;
    width: 45px;
    height: 45px;
  }

  .expcard-img > img {
    width: 30px;
  }
  .experience-details > h6 {
    font-size: 0.85rem;
    margin-bottom: 0.45rem;
  }

  .experience-details > h4 {
    font-size: 1.125rem;
    line-height: 126%;
  }

  .experience-details > h5 {
    font-size: 1.05rem;
    line-height: 126%;
  }
}

@media screen and (max-width: 600px) {
  .experience-description > h1 {
    font-size: 2.5rem;
  }
}
